
@import "src/assets/themes/mixin-breakpoints.scss";


/* ------------------------> Container Card Style <------------------------ */

 

/*================================================================================
                                  Common Classes 
==================================================================================*/

/* ------------------------> Flex Behaviors <------------------------ */
.d-flex{display: flex;}
.justify-content-start{justify-content: start;}
.justify-content-end{justify-content: flex-end;}
.justify-content-center{justify-content: center;}
.justify-content-between{justify-content: space-between;}
.align-items-start{align-items: start;}
.align-items-center{align-items: center;}
.align-items-end{align-items: end;}
.flex-column{flex-direction: column;}
.d-flex-column{display: flex;flex-direction: column;}
.position_relative{position: relative;}
.cursor-all-scroll{cursor: all-scroll !important;}
.half-containers {flex: 1;}
.gap-24{gap: 24px;}
.gap-200{gap: 200px;}
.col-gap-40{column-gap:40px;}
.mb-24{margin-bottom: 24px;}
.mb-16{margin-bottom: 16px;}
.mb-8{margin-bottom: 8px;}
.full-width{width:100%;}

/* ------------------------> Spacing Inside & Outside <------------------------ */
.m-0{margin: var(--spacing-0) !important;}
.p-0{padding: var(--spacing-0) !important;}
.pb-0{padding-bottom: var(--spacing-0) !important;}
.p-16{padding: var(--spacing-16);}


.margin-10 {margin: 10px !important;} 

.gap-20{gap:var(--spacing-20);}
.gap-16{gap:var(--spacing-16);}
.gap-12{gap:var(--spacing-12);}
.gap-8{gap:var(--spacing-8);}
.gap-4{gap:var(--spacing-4);}
.col-gap-20{column-gap:20px;}
.col-gap-16{column-gap:var(--spacing-14);}
.col-gap-12{column-gap:var(--spacing-12);}
.col-gap-14{column-gap:var(--spacing-14);}
.col-gap-8{column-gap:var(--spacing-8);}
.row-gap-8{row-gap:var(--spacing-8);}

snl-button.delete-button{
 .snl-button[type^="primary"]{
    background: var(--semantic-9) !important;
    color: var(--neutral-12) !important;
    border: none !important;
  }

  .snl-button[type^="secondary"]:not([disabled]) {
    color: var(--semantic-9);
    background-color: var(--neutral-12);
    border: var(--border-width-1) solid var(--semantic-9);
    &:hover {
      background: none;
      color: var(--semantic-9);
    }
  }
  
}



/* ------------------------> Theme Colors <------------------------ */
.color__primary_default{color: var(--font-color-primary-default);}
.color__primary{color: var(--font-color-primary);}
.color__secondary{color: var(--font-color-secondary);}
.color__neutral{color: var(--font-color-neutral);}
.color__black{color: var(--font-color-black);}
.primary-icon {--icon-color: var(--neutral-12) !important;}


/* ------------------------> Alignment <------------------------ */
.text__center{text-align: center;} 
.text__start{text-align: start;} 
.text__end{text-align: right;} 


/* ------------------------> Font Weight <------------------------ */
.fw-700{font-weight: var(--font-weight-700);}
.fw-400{font-weight: var(--font-weight-400) !important;}


/* ------------------------> Border Radius <------------------------ */ 
.border__radius4{border-radius: var(--border-radius-4);}
.border__radius6{border-radius: var(--border-radius-6);}
.border__radius8{border-radius: var(--border-radius-8);}
.border__radius999{border-radius: var(--border-radius-999);}


.text-decoration{
  text-decoration-line: underline;
}

.cursor-pointer{
  cursor: pointer;
}
/* Scroller CSS */

.scroll::-webkit-scrollbar {
    width: var(--spacing-8);
  }

  .separate-line{
    padding-bottom: var(--spacing-24);
    border-bottom: var(--spacing-1) solid var(--neutral-9);
}
  
  .scroll::-webkit-scrollbar-track {
    background-color: var(--scroll-background);
    border-width: 0px 0.5px;
    border-style: solid;
    border-color: var(--scroll-border);
  }
  
  .scroll::-webkit-scrollbar-thumb {
    border-right: 2px solid transparent;
    border-left: 2px solid transparent;
    background-clip: padding-box;
    border-radius: var(--border-radius-8);
    background-color: var(--scroll-thumb-background);
  
    &:hover {
      background-color: var(--scroll-thumb-hover);
    }
  }
  

.df-c {
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.df-s {
    display: flex;
    justify-content: start;
    align-items: start;  
}

.caption {
    font-family: var(--font-family-roboto-regular);
    font-size: var(--font-size-12);
    line-height: var(--line-height-16);
    letter-spacing: var(--letter-spacing-2);
    font-style: var(--font-style-normal);
  }

   /* ------------------------> Common Reusable Style <------------------------ */
   .theme-container{
    position: relative;
    min-height: 100vh;
    overflow: auto;
    @include media-breakpoint-only(sm) {
      padding-top: 64px;
      min-height: calc(100vh - 137px);
    }
    .theme-container-inner{
      padding: var(--spacing-0) var(--spacing-24) var(--spacing-24) var(--spacing-24); 
      min-height: 100vh;
      max-width:1224px;
      @include media-breakpoint-only(sm) {
        min-height: calc(100vh - 137px);
        padding: var(--spacing-16);
      }
    }
  }

  .add-footer{
    .theme-container{
      @include media-breakpoint-only(sm) {
        min-height: calc(100vh - 210px);
        &.footer-action-text{
          min-height: calc(100vh - 290px) !important;
        }
        
      }
    }
  }

  .breadcrumb-main{ 
    padding: var(--spacing-32) var(--spacing-0);
    h1{margin: 0px;}
    @include media-breakpoint-only(sm) { 
     display: none;
    }
    
  }

  .section {
    border: var(--spacing-1) solid var(--neutral-9);
    background: var(--neutral-12);
    border-radius: var(--border-radius-4);
  }

  .CommonContainerCard{
    background: var(--neutral-12); 
    padding: var(--spacing-24) var(--spacing-16);
    border: var(--spacing-1) solid var(--neutral-9);
    border-radius: var(--border-radius-4);
    @include media-breakpoint-only(sm) { 
      padding: var(--spacing-0);
      border: var(--spacing-0) solid var(--neutral-9);
      background: inherit; 
    }
    &.mt_0{
      margin-top: 0;
    }
    &.card-mrgn-b-32{
      margin-bottom: var(--spacing-32);
    }
    @include media-breakpoint-only(sm) { 
      &.empty__records{
        padding: 0px 0px 16px 0px !important;
        &.action{
          padding: 16px 0px 16px 0px !important;
        }
      }
    }
  }

  .tab-page{
    background: var(--neutral-12); 
    border: var(--spacing-1) solid var(--neutral-9);
    @include media-breakpoint-only(sm) { 
      background: inherit; 
      border: var(--spacing-0) solid var(--neutral-9);
    }
    .tab{
      border-bottom: var(--spacing-1) solid var(--neutral-9);
      margin-top: 16px;
      @include media-breakpoint-only(sm) { 
        margin-top: 0px;
        background: var(--neutral-12); 
        padding-top: 8px;
      }
    }
    .tab-content{
      padding: var(--spacing-24) var(--spacing-16);
      @include media-breakpoint-only(sm) { 
        padding: var(--spacing-16);
      }
    }
  }

  .border-body-tbl{
    border: var(--spacing-1) solid var(--neutral-9);
  }

  .word-wrap{
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    max-height: 24px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 1;
    color: var(--font-color-disabled);
  }
 

  .BottomStickyBar {
    position: sticky;
    left: var(--spacing-0);
    right: var(--spacing-0);
    bottom: var(--spacing-0);
    z-index: 999;
    max-width: 1224px;
    @include media-breakpoint-only(sm) { 
      display: none;
    }
  }

  .footer-action{
    display: none;
    @include media-breakpoint-only(sm) { 
      display: block;
      padding: 16px 24px;
      background: #fff;
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
      ion-grid{
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
    }

  }

  .BottomMobileBar{
    display: none;
    @include media-breakpoint-only(sm) { 
      display: block;
      position: fixed;
      bottom: 24px;
      right: 24px;
    }
    
  }

  .add-footer{
    .BottomMobileBar{
      @include media-breakpoint-only(sm) { 
        bottom: 100px;
      }
    }
    .BottomMobileBarText{
      @include media-breakpoint-only(sm) { 
        bottom: 170px;
      }
    }
  }
  .d-none{
    display: none;
    @include media-breakpoint-only(md) { 
      display: block;
   }
  }

  .m-none{
    display: block;
    @include media-breakpoint-only(md) { 
      display: none;
   }
  }

  .Skelton-pp{
    background: #e3e3e3;
    width: 100%;
    display: block;
    height: 10px; 
  }

  .Skelton-fh{
    background: #C1C4C8;
    width: 100%;
    display: block;
    height: 10px; 
  }
  .capitalize{
    text-transform: capitalize
  }

  snl-page-title{
    .content__body--title{
      span{
        display: flex;
        color: #27364b;
        align-items: center;
        gap: 4px
      }
      snl-icon{
        padding: 4px 8px;
      }
      h1{
        margin: 0px;
      }
    }
  }
  
  .input__disabled{
    .inp{
      background: var(--neutral-11) !important;
      color: var(--font-color-disabled) !important;
      pointer-events: none;
      label{
        background: var(--neutral-11) !important;
      }
      .inp__container--input{
        color: #B8BFCA;
      }
    }
   
  }



  /* ------------------------> Filters Dropdown Style <------------------------ */
.CommonFiltersPanel{
  justify-content: flex-start; 
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: initial; 
  gap: var(--spacing-16); 
  .FiltersHolder{
    display: flex;
    align-items: center;
    gap: 16px;
    flex-wrap: wrap;
  }
  .ResetFiltersTxt{
    height: 48px;
    min-width: 130px;
    @media screen and (max-width: 1300px) {
      width: 124px;
    }
   .ResetAllTxt{
     color: var(--font-color-disabled);
     text-decoration-line: none;
     cursor: not-allowed;
     &.active{
      color: var( --primary-default);
      cursor: pointer;
     }
   }
  }
  .filter-list{
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing-16);
  }
}

.TableWrapper{
  margin-top: var(--spacing-24);
  overflow-x: auto;
  overflow-y: auto;
  border: var(--spacing-1) solid var(--neutral-9);
  border-radius: var(--spacing-8);
  @include media-breakpoint-only(md) { 
    margin-top: var(--spacing-8);
    border: var(--spacing-0) solid var(--neutral-9);
  }
}
.pills{
  border-radius: 8px;
  background-color: #CBD4E1;
  padding: 2px 8px;
  font-weight: 700;
  line-height: 18px;
  font-size: 12px;
}
  

  

 
  
  

 