/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

/**
 * Ionic Dark Mode
 * -----------------------------------------------------
 * For more info, please see:
 * https://ionicframework.com/docs/theming/dark-mode
 */

@import "../node_modules/snl-complib/themes/theme-colors.scss";
@import "../node_modules/snl-complib/themes/typography.scss";
@import "../node_modules/snl-complib/themes/spacing.scss";
@import "../node_modules/snl-complib/themes/common.scss";
@import "../node_modules/snl-complib/themes/snl-complib.scss";
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.3.0/css/all.min.css");

@import "./assets/themes/theme-colors.scss";
@import "./assets/themes/typography.scss";
@import "./assets/themes/spacing.scss";
@import "./assets/themes/common.scss";
@import "./assets/themes/snl-complib.scss";
@import "./assets/themes/custome-style.scss";
@import "./assets/themes/ionic-custome-style.scss";

body {
  margin-top: constant(safe-area-inset-top);
  margin-top: env(safe-area-inset-top);
  background-color: #ffffff;
}

.plt-desktop{
  body {
    position: inherit !important;
    overflow: auto !important;
    transform: inherit !important;
    &.backdrop-no-scroll {
      overflow: hidden !important;
      position: fixed !important;
      .BottomStickyBar {
        position: absolute;
        left: 212px;
      }
    }
  }
}



:root {
  --ion-background-color: #ffffff; /* Light theme background */
  --ion-text-color: #000000; /* Light theme text */
  --ion-safe-area-bottom: 14px;
}

[data-theme='dark'] {
  --ion-background-color: #121212; /* Dark theme background */
  --ion-text-color: #ffffff; /* Dark theme text */
}


.ios-device{
  .ios-margin-bottom {
    padding-bottom: 70px !important;
  }
}

.ios {
  --ion-safe-area-top: -1 !important;
  --ion-safe-area-bottom: 14px;
}




.native{
  .ios.footer-ios {
    margin-bottom: 60px;
    &.footer-action{
      margin-bottom: 0px;
    }
  }
  &.ios{
    .footer-ios-extra-space{
      margin-bottom: 60px;
      &.filter-content{
        margin-bottom: 80px;
      }
    }
    .custom-modal-class{
      .ios.footer-ios {
        margin-bottom: 0px;
      }
    }
  }
  
 
  .action-sheet-wrapper.ion-overlay-wrapper.sc-ion-action-sheet-ios {
    margin-bottom: 60px;
  }
}


.custom-modal {
  --backdrop-opacity: 1;
  background: #fff !important;
   
    overflow: hidden !important;


  &::part(content) {
      border-radius: 20px;
      background: #fff !important;
      box-shadow: 0px 0px 20px 5px rgb(0 0 0 / 32%);
  }
}

.highlight-text{
  background-color: #FFDE81 !important;
  height: 24px;
}

