
@import "src/assets/themes/mixin-breakpoints.scss";




  ion-item{
    --min-height:0px;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    --padding-start: 0px;
  }
  .list-md{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  
  ion-col {
    padding: 0px;
  }

  .horizontal-line{
    border-bottom: 1px solid var(--neutral-9);
  }

  @mixin letter-style {
    letter-spacing: var(--letter-spacing-2);
    font-style: var(--font-style-normal);
  }

  @mixin ion-select-style {
    transform: translateY(-8px) ;
    background: var(--neutral-12);
    font-size: var(--spacing-12);
    line-height: var(--spacing-16);
    font-family: var(--font-family-roboto-regular);
    font-weight: 400;
    padding-left: 0px;
  }
  
  
  ion-radio{
    &::part(container){
      width: 16px;
      height: 16px;
      border-radius: 999px;
      border: 2px solid #ddd;
    }
    &::part(mark) {
      background: none;
      transition: none;
      transform: none;
      border-radius: 0;
    }
    &.radio-checked{
      &::part(container) {
        background: #008ECF;
        border-color: transparent;
      }
      &::part(mark) {
        border-style: solid;
        border-color: #fff;
        transform: rotate(45deg);
        width: 8px;
        height: 8px;
        border-width: 4px;
        border-radius: 999px;
      }
    }
  }

  ion-checkbox{
    &::part(container){
      border-radius: 4px;
      border-color: #94A3B8;
      --checkbox-background-checked:#008ECF;
      --size: 20px;
      padding: 2px;
      
    }
    &::part(mark) {
      background: none;
      transition: none;
      transform: none;
      border-radius: 0;
    }
    &.checkbox-checked{
      &::part(container) {
        background: #008ECF;
        border-color: transparent;
      }
      &::part(mark) {
        border-style: solid;
        border-color: #fff;
        width: 18px;
        height: 18px;
        --checkmark-width: 3px;
       
      }
    }
    
  }
 
  
 
  ion-select.ionic-dropdown{
    --border-color: none;
    --padding-start: 0px;
    border: var(--border-width-1) solid var(--neutral-9);
    border-radius: 4px;
    &.input__disabled{
      background: var(--neutral-11) !important;
      color: var(--font-color-disabled) !important;
      pointer-events: none;
    }
    &.select-label-placement-floating{
        font-family: var(--font-family-roboto-regular);
        font-size: var(--font-size-16);
        line-height: var(--line-height-24);
        color: var(--font-color-secondary);
        padding-left: 14px;
        min-height: 48px;
        &::part(label){
          margin-top: -5px;
        }
        &.has-value{
          font-weight: 700 !important;
          color: var(--font-color-primary);
          &::part(label){
            color: var(--font-color-primary);
            margin-top: 0px;
            @include ion-select-style();  
          }
          &::part(container){
            text-transform: uppercase;
          }
        }
        &.select-expanded{
          border: var(--border-width-1) solid var(--primary-default);
          &::part(label){   
            margin-top: 0px;
            color: var(--primary-default);
            @include ion-select-style();   
          }
          &::part(icon){
            color: var(--primary-default);
          }
        }
        
    }
  }

  .ios{
    ion-select.ionic-dropdown{
      &.select-label-placement-floating{
        &.has-value{
          --padding-bottom: 11px;
        }
      }
    }
  }

  .datepicker{
    .inp{
      snl-icon{
        i{
          color: var(--neutral-6) !important;
        }
      }
      @include media-breakpoint-only(md) {
        min-width: 100%;
      }
    }
    
  }

  @mixin ion-select-popover{
    font-family: var(--font-family-roboto-regular);
    font-size: var(--font-size-16);
    line-height: var(--line-height-24); 
    letter-spacing: var(--letter-spacing-2);
    font-style: var(--font-style-normal);
    color: var(--font-color-primary);
    border-bottom: var(--border-width-1) solid var(--neutral-10);
    --icon-text-color: var(--neutral-6);
  }

 

  ion-select-popover{
    ion-item.sc-ion-select-popover-md {
      --inner-border-width: 0;
      border-bottom: var(--border-width-1) solid var(--neutral-10);
      & ion-radio.sc-ion-select-popover-md::part(label){
        padding: var(--spacing-12) var(--spacing-16);
        @include ion-select-popover();
      }
      &.select-interface-option.upperinput.md.sc-ion-select-popover-md.item.item-lines-default.item-control-needs-pointer-cursor.ion-activatable.ion-focusable.hydrated:hover {
        --background: var(--neutral-11) !important;
        --icon-text-color: var(--primary-hover);
      }
    }
    .sc-ion-select-popover-ios.ios{
        @include ion-select-popover(); 
        &::part(label){
          padding: var(--spacing-12) var(--spacing-16);
          margin: var(--spacing-0);
        }
    }
  }

  .sc-ion-select-popover-md-h,.sc-ion-select-popover-ios-h {
    max-height: 200px;
  }

  .action-sheet-group.sc-ion-action-sheet-ios:first-child{
    margin-top: 10px;
    max-height: 240px;
  }
  .action-sheet-container.sc-ion-action-sheet-md {
    max-height: 300px;
  }
  
  .action-sheet-button.sc-ion-action-sheet-ios,.action-sheet-button.sc-ion-action-sheet-md {
    min-height: inherit;
    @include ion-select-popover();
  }
  
  .sc-ion-action-sheet-md,.sc-ion-action-sheet-ios {
    .action-sheet-group{
      .action-sheet-button{
        color: var(--font-color-primary);
        @include ion-select-popover();
        .action-sheet-button-inner.sc-ion-action-sheet-md {
          justify-content: center;
        }
      }
      &.sc-ion-action-sheet-md:first-child {
        margin: 10px;
        border-radius: 13px;
      }
    }
    .action-sheet-group-cancel{
      &.sc-ion-action-sheet-md {
        padding-bottom: 0px;
        border-radius: 13px;
        margin: 0px 10px 10px 10px;
        font-weight: 700;
        color:red !important;
      }
    }
    .action-sheet-cancel.sc-ion-action-sheet-md,.action-sheet-cancel.sc-ion-action-sheet-ios {
      font-weight: 700;
      color: var(--primary-default);
    }
  }

 
  .inp__helperText:empty {
    padding-top: 0px !important;
  }

  snl-action-panel{
    .buttons-gap{
        gap: 16px;
    }
}

snl-button{
  &.full-width{
    @include media-breakpoint-only(sm) { 
      width: 100%;
      button{width:100%}
    } 
  }
}

.ion-modal--full-height{
  --border-radius: 8px;
  --min-width: 800px;
  --height: auto;
  --max-width: 800px;
  --max-height:calc(100% - 80px);
    ion-footer{
      box-shadow: none !important;
    }
   
   @include media-breakpoint-only(sm) { 
    --min-width: 100%;
    --height: 100%;
    --max-width: 100%;
    --border-radius: 0px;
    --max-height:100%;
    ion-footer{
      box-shadow: 0px -2px 15px 0px #0F1A2A1A !important;
      snl-button{
        width: 100%;
        button{width:100%}
      }
    }
  }
  .wrapper{ 
    padding: 0px 24px 24px 24px;
    max-height:calc(100vh - 310px);
    overflow: auto;
  }
  ion-content{
    --padding-start:16px;
    --padding-end:16px;
    --padding-top:16px;
    ion-row{
      gap: 16px;
    }
  }
  .content{
    gap:16px;
    ion-grid{
      padding-bottom: 16px;
      gap: 16px;
      &:last-child{
        padding-bottom: 0px;
      }
    }
  }
}

ion-modal{
  &.delete-modal{
    --border-radius: 8px;
    --width: fit-content;
    --height: fit-content;
    .header{padding : var(--font-size-24)}
    .wrapper{
      padding: 0px 24px 24px 24px;
      max-width: 400px;
    }
    .modal-footer{
     padding:var(--spacing-16);
     @include media-breakpoint-only(sm) {
      .modal-actions{
        snl-button{
          width: 100%;
          button{width:100%}
        }
       }
      }
    }
    @include media-breakpoint-only(sm) {
      --width: 90%;
        &::part(backdrop) {
             --backdrop-opacity: 0.9 !important;
          }
    }
  }

  &.filter-modal{
    --height:auto;
    --border-radius: 24px 24px 0px 0px;
    &::part(backdrop) {
      --backdrop-opacity: 0.9 !important;
   }
    &.ios.modal-sheet{
      --border-radius: 24px;
    }
    snl-input{
      snl-icon{color: var(--neutral-6);}
    }
    .filter-content-list{
      display: flex;
      gap: 16px;
    }
  
    ion-item{ --border-color:var(--neutral-10); }
    .header{
      .heading{
        display: flex;
        align-items: center;
        padding-top: 24px;
        padding-bottom: 8px;
        border-bottom: 2px solid #F1F4F9;
        .icon{
          min-height: 48px;
          min-width: 48px;
          justify-content: center;
          align-items: center;
          display: flex;
        }
      }
    }
    .dropdown-content {
      .search-bar {
        padding: 8px 16px;
        border-bottom: 1px solid #F1F4F9
      }
      .item-content{
        overflow-y: auto;
      }
      ion-list-header{
        padding: 2px 16px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      ion-item {
        border-bottom: 1px solid #F1F4F9;
        padding: 16px;
        --padding-bottom: 12px;
        --padding-top: 12px;
      }
     
      .inner-content{
        overflow: auto;
        max-height: 284px;
        .list{
          min-height:48px;
          display: flex;
          align-items: center;
          padding: 0 16px;
          cursor: pointer;
          border-bottom: 1px solid #F1F4F9;
          &:last-child{
            border-bottom: none;
          }
          &:hover{
            background-color: #F1F4F9;
          }
          ion-label {
            padding: 0px 16px;
          }
        }
      }
      
    }
    .filter-list{
      ion-label {
        padding: 16px;
      }
    }
   
    .button {
      padding: 16px;
      display: flex;
      gap: 16px;
      box-shadow: none;
      snl-button {
        width: 100%;
        button {
          width: 100%;
        }
      }
    }
    .filter-box {
      background-color: #F1F4F9;
      justify-content: space-between;
      display: flex;
      align-items: center;
      padding: 12px 16px;
    
      ion-title {
        padding: 0px;
      }
      .reset-btn {
        color: #B8BFCA;
        font-size: 16px;
        text-align: end;
        text-decoration: none;
      }
    }
    .modal-footer-mob {
      justify-content: space-between;
      display: flex;
      align-items: center;
      padding: 12px 16px;
      border-bottom: 1px solid #F1F4F9;
    }
    .filter-item {
      height: 56px !important;
    }
    .bold-label {
      font-family: var(--font-family-roboto-bold) !important;
    }
    .active-reset{
      color:#008ECF !important
    }
  }
}
 

ion-skeleton-text{
  &.input{
      width:100%;
      border-radius: 4px;
      height: 48px;
  }
  &.wPx-100{
    width: 100px;
  }
  &.dropdown{
    width: 130px;
    height: 48px;
    border-radius: 4px;
  }
  &.search{
    width: 260px;
    height: 48px;
    border-radius: 4px;
  }
  &.button{
    width: 200px;
    height: 48px;
    border-radius: 4px;
    @include media-breakpoint-only(sm) {
      width: inherit;
    }

  }
  &.mobile-button{
    max-width: 48px;
    min-width: 48px;
    height: 48px;
    border-radius: 4px;
  }
  &.toogle{
    width: 60px;
    height: 30px;
    border-radius: 999px
  }
  &.text{
    margin-top: 8px;
    width: 130px;
    height: 10px;
  }
  &.heading{
    width: 160px;
    height: 18px;
  }
  &.w-100{
    width:100%
  }
  &.w-80{
    width:80%
  }
  &.w-50{
    width:50%
  }
  &.w-20{
    width:20%
  }
  &.tabskeleton{
    margin-top: 8px;
    width: 120px;
    height: 32px;
    border-radius: 4px 4px 0px 0px;
  }
  &.drga-drop{
    border-radius: var(--border-radius-8);
    min-height: 114px;
    margin-bottom: 12px;
  }
  &.name{
    width: 130px;
    margin-bottom: 8px;
    height: 12px;
  }
  &.designation{
      width: 70px;
  }
}

ion-thumbnail {
  &.profile{
    --border-radius: var(--border-radius-999);
    width: 32px;
    height: 32px;
  }
     
}



ion-content{
  --background: none;
 }

 ion-infinite-scroll-content{
  min-height: 50px;
  .infinite-loading{
    margin-bottom: 0px;
  }
 }

 ion-footer{
  &.bottom-sticky{
    max-width: 1224px;
    @include media-breakpoint-only(sm) { 
      display: none;
    }
  }
 }

ion-grid{
  --ion-grid-padding: 0px;
  ion-row{
    ion-col{
      &:nth-child(1){
          padding-right: 18px !important;
          @include media-breakpoint-only(md) {
              padding-right: 0px !important;
          }
      }
      &:nth-child(2){
          padding-right: 12px;
          padding-left: 6px;
          @include media-breakpoint-only(md) {
              padding-right: 0px;
              padding-left: 0px;
          }
      }
      &:nth-child(3){
          padding-right: 6px;
          padding-left: 12px;
          @include media-breakpoint-only(md) {
              padding-right: 0px;
              padding-left: 0px;
          }
      }
      &:nth-child(4){
          padding-left: 18px;
          @include media-breakpoint-only(md) {
              padding-left: 0px;
          }
      }
      &:last-child{
        padding-right: 0px;
      }
     
      @include media-breakpoint-only(md) {
          padding-right: 0px; 
          padding-left: 0px;
      }
  }
  } 
}

 
  
  

 