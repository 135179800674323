/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";
/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";
/**
 * Ionic Dark Mode
 * -----------------------------------------------------
 * For more info, please see:
 * https://ionicframework.com/docs/theming/dark-mode
 */
@import "~@angular/cdk/overlay-prebuilt.css";
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.3.0/css/all.min.css");
:root {
  /**
  *Neutral Colors
  */
  --neutral-1: #0F1A2A;
  --neutral-2: #1E2A3B;
  --neutral-3: #27364B;
  --neutral-4: #475569;
  --neutral-5: #64748B;
  --neutral-6: #788496;
  --neutral-7: #94A3B8;
  --neutral-8: #CBD4E1;
  --neutral-9: #DBE3ED;
  --neutral-10: #F1F4F9;
  --neutral-11: #F6F8FC;
  --neutral-12: #FFFFFF;
  /**
  *Primary
  */
  --primary-default: #008ECF;
  --primary-hover: #006B9C;
  --primary-selected: #004869;
  --primary-disabled: #CBD4E1;
  --primary-accent: #E5F4FA;
  /**
  *Font Colors
  */
  --font-color-primary: #27364B;
  --font-color-secondary: #64748B;
  --font-color-neutral: #8392A8;
  --font-color-disabled: #B8BFCA;
  --font-color-button: #FFFFFF;
  /**
  *Pastel Colors
  */
  --pastel-1: #FFADAD;
  --pastel-2: #FEC89A;
  --pastel-3: #7FC8F8;
  --pastel-4: #E0D2FF;
  --pastel-5: #FFE8B7;
  --pastel-6: #C7F5E3;
  --pastel-7: #DAD1CA;
  --pastel-8: #FCF3D3;
  --pastel-9: #ECCBD9;
  --pastel-10: #AFEFF5;
  /**
  *Semantic Colors
  */
  --semantic-1: #0C9E0B;
  --semantic-2: #E8FCF1;
  --semantic-3: #A5E1BF;
  --semantic-4: #00401C;
  --semantic-5: #FFA300;
  --semantic-6: #FFF5D5;
  --semantic-7: #FFDE81;
  --semantic-8: #724B00;
  --semantic-9: #CF0027;
  --semantic-10: #FFEBEB;
  --semantic-11: #FC9595;
  --semantic-12: #8C0000;
  /**
  * Scroller Colors
  */
  --scroll-background: #fafafa;
  --scroll-border: #e8e8e8;
  --scroll-thumb-background: #d9d9d9;
  --scroll-thumb-hover: #7d7d7d;
  /**
  * backdrop background
  */
  --backdrop-background: rgba(0,0,0,.32);
}

/**
  * Common font family
  */
@font-face {
  font-family: pt-serif-bold;
  src: url("./fonts/PTSerif-Bold.ttf") format("truetype");
}
@font-face {
  font-family: roboto-bold;
  src: url("./fonts/Roboto-Bold.ttf") format("truetype");
}
@font-face {
  font-family: roboto-regular;
  src: url("./fonts/Roboto-Regular.ttf") format("truetype");
}
:root {
  /**
  * Font weight
  */
  --font-weight-100: 100;
  --font-weight-200: 200;
  --font-weight-300: 300;
  --font-weight-400: 400;
  --font-weight-500: 500;
  --font-weight-600: 600;
  --font-weight-700: 700;
  --font-weight-800: 800;
  --font-weight-900: 900;
  /**
  * Common font family
  */
  --font-family-pt-serif-bold: pt-serif-bold;
  --font-family-roboto-bold: roboto-bold;
  --font-family-roboto-regular: roboto-regular;
  /**
  * Letter spacing
  */
  --letter-spacing-2: 0.2px;
  /**
  * Border width
  */
  --border-width-1: 1px;
  --border-width-2: 2px;
  --border-width-4: 4px;
  --border-width-8: 8px;
  /**
  * Border radius
  */
  --border-radius-4: 4px;
  --border-radius-8: 8px;
  --border-radius-6: 6px;
  --border-radius-999: 999px;
  /**
  * Font size
  */
  --font-size-1: 1px;
  --font-size-2: 2px;
  --font-size-4: 4px;
  --font-size-8: 8px;
  --font-size-12: 12px;
  --font-size-14: 14px;
  --font-size-16: 16px;
  --font-size-20: 20px;
  --font-size-24: 24px;
  --font-size-26: 26px;
  --font-size-32: 32px;
  --font-size-40: 40px;
  --font-size-48: 48px;
  --font-size-56: 56px;
  --font-size-64: 64px;
  --font-size-72: 72px;
  --font-size-80: 80px;
  /**
  * Font style
  */
  --font-style-normal: normal;
  /**
  * Line Height
  */
  --line-height-16: 16px;
  --line-height-24: 24px;
  --line-height-32: 32px;
  --line-height-40: 40px;
  /**
    *Elevations
    */
  --elevation-e1: 0px 1px 2px rgba(15, 26, 42, 0.25);
  --elevation-e2: 0px 0px 20px rgba(15, 26, 42, 0.15);
  --elevation-e3: 0px 10px 15px rgba(15, 26, 42, 0.1);
  --elevation-e4: 0px -2px 15px rgba(15, 26, 42, 0.1);
  --elevation-e5: 0px 3px 12px rgba(15, 26, 42, 0.25);
}

:root {
  /**
  *Spacing
  */
  --spacing-1: 1px;
  --spacing-2: 2px;
  --spacing-4: 4px;
  --spacing-8: 8px;
  --spacing-12: 12px;
  --spacing-16: 16px;
  --spacing-24: 24px;
  --spacing-32: 32px;
  --spacing-38: 38px;
  --spacing-40: 40px;
  --spacing-48: 48px;
  --spacing-56: 56px;
  --spacing-64: 64px;
  --spacing-72: 72px;
  --spacing-80: 80px;
}

.header-h1 {
  font-family: var(--font-family-pt-serif-bold);
  font-size: var(--font-size-26);
  line-height: var(--line-height-40);
  letter-spacing: var(--letter-spacing-2);
  font-style: var(--font-style-normal);
}

.header-h2 {
  font-family: var(--font-family-roboto-bold);
  font-size: var(--font-size-26);
  line-height: var(--line-height-40);
  letter-spacing: var(--letter-spacing-2);
  font-style: var(--font-style-normal);
}

.sub-heading-s1 {
  font-family: var(--font-family-roboto-bold);
  font-size: var(--font-size-20);
  line-height: var(--line-height-32);
  letter-spacing: var(--letter-spacing-2);
  font-style: var(--font-style-normal);
}

.sub-heading-s2 {
  font-family: var(--font-family-roboto-regular);
  font-size: var(--font-size-20);
  line-height: var(--line-height-32);
  letter-spacing: var(--letter-spacing-2);
  font-style: var(--font-style-normal);
}

.section-title {
  font-family: var(--font-family-roboto-bold);
  font-size: var(--font-size-16);
  line-height: var(--line-height-24);
  letter-spacing: var(--letter-spacing-2);
  font-style: var(--font-style-normal);
}

.body-b1 {
  font-family: var(--font-family-roboto-regular);
  font-size: var(--font-size-16);
  line-height: var(--line-height-24);
  color: var(--font-color-secondary);
  letter-spacing: var(--letter-spacing-2);
  font-style: var(--font-style-normal);
}

.body-b2 {
  font-family: var(--font-family-roboto-bold);
  font-size: var(--font-size-14);
  line-height: var(--line-height-24);
  letter-spacing: var(--letter-spacing-2);
  font-style: var(--font-style-normal);
}

.body-b {
  font-family: var(--font-family-roboto-regular);
  font-size: var(--font-size-14);
  line-height: var(--line-height-24);
  letter-spacing: var(--letter-spacing-2);
  font-style: var(--font-style-normal);
}

.labels {
  font-family: var(--font-family-roboto-bold);
  font-size: var(--font-size-12);
  line-height: var(--line-height-16);
  letter-spacing: var(--letter-spacing-2);
  font-style: var(--font-style-normal);
}

.caption {
  font-family: var(--font-family-roboto-regular);
  font-size: var(--font-size-12);
  line-height: var(--line-height-16);
  letter-spacing: var(--letter-spacing-2);
  font-style: var(--font-style-normal);
}

.df-c {
  display: flex;
  justify-content: center;
  align-items: center;
}

.resize-date-picker-overlay {
  width: auto !important;
}

snl-menu-list-item :focus-visible {
  outline: var(--neutral-10) solid 0px;
}

.container__menu-item--value b {
  color: var(--dropdown-label-value-color);
  font-family: var(--font-family-roboto-bold);
  font-size: var(--font-size-16);
  line-height: var(--line-height-24);
  letter-spacing: var(--letter-spacing-2);
  font-style: var(--font-style-normal);
}

* {
  margin: 0;
  box-sizing: border-box;
}

/* Scroller CSS for both top and bottom scrollbars */
.tableWrapper::-webkit-scrollbar,
.scroll::-webkit-scrollbar {
  width: var(--spacing-8);
  height: var(--spacing-8);
}

.tableWrapper::-webkit-scrollbar-track,
.scroll::-webkit-scrollbar-track {
  background-color: var(--scroll-background);
  border-width: 0px 0.5px;
  border-style: solid;
  border-color: var(--scroll-border);
}

.tableWrapper::-webkit-scrollbar-thumb,
.scroll::-webkit-scrollbar-thumb {
  border-right: 2px solid transparent;
  border-left: 2px solid transparent;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  background-clip: padding-box;
  border-radius: var(--border-radius-8);
  background-color: var(--scroll-thumb-background);
}

.tableWrapper::-webkit-scrollbar-thumb:hover,
.scroll::-webkit-scrollbar-thumb:hover {
  background-color: var(--scroll-thumb-hover);
}

/* Top scrollbar wrapper */
.tableWrapper {
  width: 100%;
  height: 8px;
  overflow-x: auto; /* Horizontal scroll enabled */
  overflow-y: hidden;
}

.wrapperTop {
  width: 100%;
  height: 0px;
  overflow-x: hidden; /* Horizontal scroll enabled */
  overflow-y: hidden;
}

/* Hidden div to simulate top scrollbar width */
.div1 {
  width: 2000px; /* Ensure this is wider than the table */
  height: 20px;
}

/* Bottom scroll wrapper */
.scroll {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.scrollTop {
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}

:root {
  /**
  *Neutral Colors
  */
  --neutral-1: #0F1A2A;
  --neutral-2: #1E2A3B;
  --neutral-3: #27364B;
  --neutral-4: #475569;
  --neutral-5: #64748B;
  --neutral-6: #788496;
  --neutral-7: #94A3B8;
  --neutral-8: #CBD4E1;
  --neutral-9: #DBE3ED;
  --neutral-10: #F1F4F9;
  --neutral-11: #F6F8FC;
  --neutral-12: #FFFFFF;
  /**
  *Primary
  */
  --primary-default: #008ECF;
  --primary-hover: #006B9C;
  --primary-selected: #004869;
  --primary-disabled: #CBD4E1;
  --primary-accent: #E5F4FA;
  /**
  *Font Colors
  */
  --font-color-primary: #27364B;
  --font-color-primary-default: #008ECF;
  --font-color-secondary: #64748B;
  --font-color-neutral: #8392A8;
  --font-color-disabled: #B8BFCA;
  --font-color-button: #FFFFFF;
  --font-color-black: #000000;
  /**
  *Pastel Colors
  */
  --pastel-1: #FFADAD;
  --pastel-2: #FEC89A;
  --pastel-3: #7FC8F8;
  --pastel-4: #E0D2FF;
  --pastel-5: #FFE8B7;
  --pastel-6: #C7F5E3;
  --pastel-7: #DAD1CA;
  --pastel-8: #FCF3D3;
  --pastel-9: #ECCBD9;
  --pastel-10: #AFEFF5;
  /**
  *Semantic Colors
  */
  --semantic-1: #0C9E0B;
  --semantic-2: #E8FCF1;
  --semantic-3: #A5E1BF;
  --semantic-4: #00401C;
  --semantic-5: #FFA300;
  --semantic-6: #FFF5D5;
  --semantic-7: #FFDE81;
  --semantic-8: #724B00;
  --semantic-9: #CF0027;
  --semantic-10: #FFEBEB;
  --semantic-11: #FC9595;
  --semantic-12: #8C0000;
  /**
  * Scroller Colors
  */
  --scroll-background: #fafafa;
  --scroll-border: #e8e8e8;
  --scroll-thumb-background: #d9d9d9;
  --scroll-thumb-hover: #7d7d7d;
  /**
  * backdrop background
  */
  --backdrop-background: rgba(0,0,0,.32);
}

/**
  * Common font family
  */
@font-face {
  font-family: pt-serif-bold;
  src: url("./fonts/PTSerif-Bold.ttf") format("truetype");
}
@font-face {
  font-family: roboto-bold;
  src: url("./fonts/Roboto-Bold.ttf") format("truetype");
}
@font-face {
  font-family: roboto-regular;
  src: url("./fonts/Roboto-Regular.ttf") format("truetype");
}
:root {
  /**
  * Font weight
  */
  --font-weight-100: 100;
  --font-weight-200: 200;
  --font-weight-300: 300;
  --font-weight-400: 400;
  --font-weight-500: 500;
  --font-weight-600: 600;
  --font-weight-700: 700;
  --font-weight-800: 800;
  --font-weight-900: 900;
  /**
  * Common font family
  */
  --font-family-pt-serif-bold: pt-serif-bold;
  --font-family-roboto-bold: roboto-bold;
  --font-family-roboto-regular: roboto-regular;
  /**
  * Letter spacing
  */
  --letter-spacing-2: 0.2px;
  /**
  * Border width
  */
  --border-width-1: 1px;
  --border-width-2: 2px;
  --border-width-4: 4px;
  --border-width-8: 8px;
  /**
  * Border radius
  */
  --border-radius-4: 4px;
  --border-radius-8: 8px;
  --border-radius-6: 6px;
  --border-radius-999: 999px;
  /**
  * Font size
  */
  --font-size-1: 1px;
  --font-size-2: 2px;
  --font-size-4: 4px;
  --font-size-8: 8px;
  --font-size-12: 12px;
  --font-size-14: 14px;
  --font-size-16: 16px;
  --font-size-20: 20px;
  --font-size-24: 24px;
  --font-size-26: 26px;
  --font-size-32: 32px;
  --font-size-40: 40px;
  --font-size-48: 48px;
  --font-size-56: 56px;
  --font-size-64: 64px;
  --font-size-72: 72px;
  --font-size-80: 80px;
  /**
  * Font style
  */
  --font-style-normal: normal;
  /**
  * Line Height
  */
  --line-height-16: 16px;
  --line-height-24: 24px;
  --line-height-32: 32px;
  --line-height-40: 40px;
  /**
    *Elevations
    */
  --elevation-e1: 0px 1px 2px rgba(15, 26, 42, 0.25);
  --elevation-e2: 0px 0px 20px rgba(15, 26, 42, 0.15);
  --elevation-e3: 0px 10px 15px rgba(15, 26, 42, 0.1);
  --elevation-e4: 0px -2px 15px rgba(15, 26, 42, 0.1);
  --elevation-e5: 0px 3px 12px rgba(15, 26, 42, 0.25);
}

:root {
  /**
  *Spacing
  */
  --spacing-0: 0px;
  --spacing-1: 1px;
  --spacing-2: 2px;
  --spacing-4: 4px;
  --spacing-8: 8px;
  --spacing-12: 12px;
  --spacing-14: 14px;
  --spacing-16: 16px;
  --spacing-24: 24px;
  --spacing-28: 28px;
  --spacing-32: 32px;
  --spacing-36: 36px;
  --spacing-38: 38px;
  --spacing-40: 40px;
  --spacing-48: 48px;
  --spacing-56: 56px;
  --spacing-64: 64px;
  --spacing-72: 72px;
  --spacing-80: 80px;
  --spacing-90: 90px;
}

/* Hide the toolbar on larger screens (tablet and desktop) */
.header-h1 {
  font-family: var(--font-family-pt-serif-bold);
  font-size: var(--font-size-26);
  line-height: var(--line-height-40);
  letter-spacing: var(--letter-spacing-2);
  font-style: var(--font-style-normal);
}

.header-h2 {
  font-family: var(--font-family-roboto-bold);
  font-size: var(--font-size-26);
  line-height: var(--line-height-40);
  letter-spacing: var(--letter-spacing-2);
  font-style: var(--font-style-normal);
}

.sub-heading-s1 {
  font-family: var(--font-family-roboto-bold);
  font-size: var(--font-size-20);
  line-height: var(--line-height-32);
  letter-spacing: var(--letter-spacing-2);
  font-style: var(--font-style-normal);
}

.sub-heading-s2 {
  font-family: var(--font-family-roboto-regular);
  font-size: var(--font-size-20);
  line-height: var(--line-height-32);
  letter-spacing: var(--letter-spacing-2);
  font-style: var(--font-style-normal);
}

.section-title {
  font-family: var(--font-family-roboto-bold);
  font-size: var(--font-size-16);
  line-height: var(--line-height-24);
  letter-spacing: var(--letter-spacing-2);
  font-style: var(--font-style-normal);
}

.body-b1 {
  font-family: var(--font-family-roboto-regular);
  font-size: var(--font-size-16);
  line-height: var(--line-height-24);
  color: var(--font-color-secondary);
  letter-spacing: var(--letter-spacing-2);
  font-style: var(--font-style-normal);
}

.body-b2 {
  font-family: var(--font-family-roboto-bold);
  font-size: var(--font-size-14);
  line-height: var(--line-height-24);
  letter-spacing: var(--letter-spacing-2);
  font-style: var(--font-style-normal);
}

.body-b3 {
  font-family: var(--font-family-roboto-regular);
  font-size: var(--font-size-14);
  line-height: var(--line-height-24);
  letter-spacing: var(--letter-spacing-2);
  font-style: var(--font-style-normal);
}

.body-b {
  font-family: var(--font-family-roboto-regular);
  font-size: var(--font-size-14);
  line-height: var(--line-height-24);
  letter-spacing: var(--letter-spacing-2);
  font-style: var(--font-style-normal);
}

.labels {
  font-family: var(--font-family-roboto-bold);
  font-size: var(--font-size-12);
  line-height: var(--line-height-16);
  letter-spacing: var(--letter-spacing-2);
  font-style: var(--font-style-normal);
}

.caption {
  font-family: var(--font-family-roboto-regular);
  font-size: var(--font-size-12);
  line-height: var(--line-height-16);
  letter-spacing: var(--letter-spacing-2);
  font-style: var(--font-style-normal);
}

.mobile-only-toolbar {
  display: none;
  --background: var(--neutral-12) ;
}

.header-md {
  -webkit-box-shadow: inherit;
}

.mb-16 {
  margin-bottom: 16px;
}

/* Show the toolbar only on small screens (mobile) */
@media (max-width: 767px) {
  .mobile-only-toolbar {
    display: flex;
    height: 64px;
    border-bottom: 1px solid #DBE3ED;
    align-items: center;
    justify-content: center;
  }
}
ion-header .word-wrap {
  max-height: inherit;
}

.navigation-header ion-menu-button, .navigation-header ion-icon {
  color: var(--neutral-6);
}
.navigation-header .header {
  padding: 24px;
}
.navigation-header .navigation-title {
  display: flex;
  align-items: center;
  justify-content: center;
}
.navigation-header ion-title {
  padding-inline: 0px;
}
.navigation-header ion-buttons {
  cursor: pointer;
  color: #788496;
}
.navigation-header ion-buttons.buttons-first-slot {
  padding: 16px;
  width: 48px;
  height: 48px;
  margin-left: 4px;
}
.navigation-header ion-buttons.buttons-last-slot {
  width: 48px;
  height: 48px;
  margin-right: 4px;
}
.navigation-header ion-buttons.buttons-last-slot .fa-bell {
  font-size: 16px;
}

.header-ios ion-toolbar:last-of-type {
  --border-width: 0px;
}

ion-footer {
  padding: 16px;
  background: #fff;
  box-shadow: 0px -2px 15px 0px rgba(15, 26, 42, 0.1019607843);
}
ion-footer ion-grid {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
ion-footer ion-grid .button {
  display: flex;
  gap: 24px;
}
ion-footer ion-grid .button.footer-submit-btn {
  flex-direction: row-reverse;
}
ion-footer ion-grid .button.footer-submit-btn .save__draft, ion-footer ion-grid .button.footer-submit-btn .save__submit {
  width: 100%;
}
ion-footer ion-grid .button.delete-cancel {
  flex-direction: column;
}
ion-footer ion-grid .button.delete-cancel .save__draft {
  display: flex;
  flex: 50%;
  gap: 24px;
}
ion-footer ion-grid .button.delete-cancel .save__draft snl-button {
  width: 100%;
}
ion-footer ion-grid .button .snl-button[size^=large]:not([iconOnly^=true]) {
  padding: inherit;
  width: 100%;
}

.add-footer ion-footer {
  padding: 16px 24px;
}

.upperinput input {
  text-transform: uppercase;
}

.profile-details {
  gap: 16px;
}
.profile-details .uplaod-image .drag-drop {
  border-radius: var(--border-radius-8);
  padding: var(--spacing-16);
  align-items: center;
  justify-content: space-between;
  display: flex;
  border: 1px solid #CBD4E1;
  border-style: dashed;
  margin-bottom: var(--border-width-4);
}
@media (max-width: 991px) {
  .profile-details .uplaod-image .drag-drop {
    padding: var(--spacing-0);
    border: none;
    margin: var(--spacing-0);
  }
  .profile-details .uplaod-image .drag-drop .body-b1 {
    display: none;
  }
  .profile-details .uplaod-image .drag-drop snl-button {
    width: 100%;
  }
  .profile-details .uplaod-image .drag-drop snl-button button {
    width: 100%;
    margin-bottom: var(--spacing-16);
  }
}
.profile-details .uploaded-files-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 80%;
}
.profile-details ul {
  padding-left: 10px;
  max-height: 32px;
}

@media (max-width: 767px) {
  .form-layout .gap-24 {
    gap: 16px;
  }
  .form-layout .gap-200 {
    gap: 200px;
  }
}

.uploaded-files-heading {
  font-family: var(--font-family-roboto-regular);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.2px;
}

* {
  margin: 0;
  box-sizing: border-box;
}

/* Scroller CSS */
.scroll::-webkit-scrollbar {
  width: var(--spacing-8);
}

.scroll::-webkit-scrollbar-track {
  background-color: var(--scroll-background);
  border-width: 0px 0.5px;
  border-style: solid;
  border-color: var(--scroll-border);
}

.scroll::-webkit-scrollbar-thumb {
  border-right: 2px solid transparent;
  border-left: 2px solid transparent;
  background-clip: padding-box;
  border-radius: var(--border-radius-8);
  background-color: var(--scroll-thumb-background);
}
.scroll::-webkit-scrollbar-thumb:hover {
  background-color: var(--scroll-thumb-hover);
}

snl-page-tab .page-tab:hover {
  background-color: inherit !important;
}

snl-toast {
  margin-top: 40px;
}
@media (max-width: 767px) {
  snl-toast .container {
    margin: auto !important;
  }
}

.action-panel-main snl-action-panel {
  box-shadow: none;
  padding: var(--spacing-0);
  display: flex;
  align-items: flex-start;
}
@media (max-width: 991px) {
  .action-panel-main {
    display: none;
  }
}

snl-input i {
  color: inherit !important;
}
snl-input.search-input {
  min-width: 280px;
  width: 100%;
}
@media (max-width: 767px) {
  snl-input.search-input {
    width: 100%;
    min-width: inherit;
  }
  snl-input.search-input i {
    color: var(--neutral-6) !important;
  }
}

snl-table .align-right .heading-text, snl-table .align-right .data-text {
  justify-content: end !important;
}
snl-table .align-right .data-text:not(:empty) {
  max-width: inherit !important;
}
@media (max-width: 991px) {
  snl-table .align-right .heading-text, snl-table .align-right .data-text {
    justify-content: start !important;
  }
}
snl-table.tableCard .label, snl-table.tableCard .d-none {
  display: none;
}
@media (max-width: 991px) {
  snl-table.tableCard .table-heading {
    display: none !important;
  }
  snl-table.tableCard .table__wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  snl-table.tableCard .table__wrapper snl-row {
    background: var(--neutral-12);
    display: flex;
    flex-direction: column;
    border: var(--spacing-1) solid var(--neutral-9);
    border-radius: var(--border-radius-4);
    padding: var(--spacing-16);
    gap: 16px;
  }
  snl-table.tableCard .table__wrapper snl-row snl-td {
    border-bottom: 0px;
  }
  snl-table.tableCard .table__wrapper snl-row snl-td.td-none {
    display: none;
  }
  snl-table.tableCard .table__wrapper snl-row snl-td.d-none {
    display: block;
  }
  snl-table.tableCard .table__wrapper snl-row snl-td .td__wrapper .data-text:not(:empty) {
    max-width: 100% !important;
    padding: var(--spacing-0) !important;
  }
  snl-table.tableCard .table__wrapper snl-row snl-td .label-value {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  snl-table.tableCard .table__wrapper snl-row snl-td .label-value .label {
    display: block;
  }
  snl-table.tableCard .table__wrapper snl-row snl-td .label-value .value {
    font-family: var(--font-family-roboto-bold);
    font-size: var(--font-size-16);
    line-height: var(--line-height-24);
    letter-spacing: var(--letter-spacing-2);
    font-style: var(--font-style-normal);
    color: var(--font-color-primary);
  }
}
@media (max-width: 991px) and (max-width: 991px) {
  snl-table.tableCard .table__wrapper snl-row snl-td .td__wrapper .text-content {
    white-space: inherit !important;
  }
}

/* ------------------------> Container Card Style <------------------------ */
/*================================================================================
                                  Common Classes 
==================================================================================*/
/* ------------------------> Flex Behaviors <------------------------ */
.d-flex {
  display: flex;
}

.justify-content-start {
  justify-content: start;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-start {
  align-items: start;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: end;
}

.flex-column {
  flex-direction: column;
}

.d-flex-column {
  display: flex;
  flex-direction: column;
}

.position_relative {
  position: relative;
}

.cursor-all-scroll {
  cursor: all-scroll !important;
}

.half-containers {
  flex: 1;
}

.gap-24 {
  gap: 24px;
}

.gap-200 {
  gap: 200px;
}

.mb-24 {
  margin-bottom: 24;
}

.mb-16 {
  margin-bottom: 16;
}

.full-width {
  width: 100%;
}

/* ------------------------> Spacing Inside & Outside <------------------------ */
.m-0 {
  margin: var(--spacing-0) !important;
}

.p-0 {
  padding: var(--spacing-0) !important;
}

.pb-0 {
  padding-bottom: var(--spacing-0) !important;
}

.p-16 {
  padding: var(--spacing-16);
}

.margin-10 {
  margin: 10px !important;
}

.gap-20 {
  gap: var(--spacing-20);
}

.gap-16 {
  gap: var(--spacing-16);
}

.gap-12 {
  gap: var(--spacing-12);
}

.gap-8 {
  gap: var(--spacing-8);
}

.col-gap-20 {
  column-gap: 20px;
}

.col-gap-16 {
  column-gap: var(--spacing-14);
}

.col-gap-12 {
  column-gap: var(--spacing-12);
}

.col-gap-14 {
  column-gap: var(--spacing-14);
}

.col-gap-8 {
  column-gap: var(--spacing-8);
}

.row-gap-8 {
  row-gap: var(--spacing-8);
}

snl-button.delete-button .snl-button[type^=primary] {
  background: var(--semantic-9) !important;
  color: var(--neutral-12) !important;
  border: none !important;
}
snl-button.delete-button .snl-button[type^=secondary]:not([disabled]) {
  color: var(--semantic-9);
  background-color: var(--neutral-12);
  border: var(--border-width-1) solid var(--semantic-9);
}
snl-button.delete-button .snl-button[type^=secondary]:not([disabled]):hover {
  background: none;
  color: var(--semantic-9);
}

/* ------------------------> Theme Colors <------------------------ */
.color__primary_default {
  color: var(--font-color-primary-default);
}

.color__primary {
  color: var(--font-color-primary);
}

.color__secondary {
  color: var(--font-color-secondary);
}

.color__neutral {
  color: var(--font-color-neutral);
}

.color__black {
  color: var(--font-color-black);
}

.primary-icon {
  --icon-color: var(--neutral-12) !important;
}

/* ------------------------> Alignment <------------------------ */
.text__center {
  text-align: center;
}

.text__start {
  text-align: start;
}

.text__end {
  text-align: right;
}

/* ------------------------> Font Weight <------------------------ */
.fw-700 {
  font-weight: var(--font-weight-700);
}

.fw-400 {
  font-weight: var(--font-weight-400) !important;
}

/* ------------------------> Border Radius <------------------------ */
.border__radius4 {
  border-radius: var(--border-radius-4);
}

.border__radius6 {
  border-radius: var(--border-radius-6);
}

.border__radius8 {
  border-radius: var(--border-radius-8);
}

.border__radius999 {
  border-radius: var(--border-radius-999);
}

.text-decoration {
  text-decoration-line: underline;
}

.cursor-pointer {
  cursor: pointer;
}

/* Scroller CSS */
.scroll::-webkit-scrollbar {
  width: var(--spacing-8);
}

.separate-line {
  padding-bottom: var(--spacing-24);
  border-bottom: var(--spacing-1) solid var(--neutral-9);
}

.scroll::-webkit-scrollbar-track {
  background-color: var(--scroll-background);
  border-width: 0px 0.5px;
  border-style: solid;
  border-color: var(--scroll-border);
}

.scroll::-webkit-scrollbar-thumb {
  border-right: 2px solid transparent;
  border-left: 2px solid transparent;
  background-clip: padding-box;
  border-radius: var(--border-radius-8);
  background-color: var(--scroll-thumb-background);
}
.scroll::-webkit-scrollbar-thumb:hover {
  background-color: var(--scroll-thumb-hover);
}

.df-c {
  display: flex;
  justify-content: center;
  align-items: center;
}

.df-s {
  display: flex;
  justify-content: start;
  align-items: start;
}

.caption {
  font-family: var(--font-family-roboto-regular);
  font-size: var(--font-size-12);
  line-height: var(--line-height-16);
  letter-spacing: var(--letter-spacing-2);
  font-style: var(--font-style-normal);
}

/* ------------------------> Common Reusable Style <------------------------ */
.theme-container {
  position: relative;
  min-height: 100vh;
  overflow: auto;
}
@media (max-width: 767px) {
  .theme-container {
    padding-top: 64px;
    min-height: calc(100vh - 137px);
  }
}
.theme-container .theme-container-inner {
  padding: var(--spacing-0) var(--spacing-24) var(--spacing-24) var(--spacing-24);
  min-height: 100vh;
  max-width: 1224px;
}
@media (max-width: 767px) {
  .theme-container .theme-container-inner {
    min-height: calc(100vh - 137px);
    padding: var(--spacing-16);
  }
}

@media (max-width: 767px) {
  .add-footer .theme-container {
    min-height: calc(100vh - 210px);
  }
  .add-footer .theme-container.footer-action-text {
    min-height: calc(100vh - 290px) !important;
  }
}

.breadcrumb-main {
  padding: var(--spacing-32) var(--spacing-0);
}
.breadcrumb-main h1 {
  margin: 0px;
}
@media (max-width: 767px) {
  .breadcrumb-main {
    display: none;
  }
}

.section {
  border: var(--spacing-1) solid var(--neutral-9);
  background: var(--neutral-12);
  border-radius: var(--border-radius-4);
}

.CommonContainerCard {
  background: var(--neutral-12);
  padding: var(--spacing-24) var(--spacing-16);
  border: var(--spacing-1) solid var(--neutral-9);
  border-radius: var(--border-radius-4);
}
@media (max-width: 767px) {
  .CommonContainerCard {
    padding: var(--spacing-0);
    border: var(--spacing-0) solid var(--neutral-9);
    background: inherit;
  }
}
.CommonContainerCard.mt_0 {
  margin-top: 0;
}
.CommonContainerCard.card-mrgn-b-32 {
  margin-bottom: var(--spacing-32);
}
@media (max-width: 767px) {
  .CommonContainerCard.empty__records {
    padding: 0px 0px 16px 0px !important;
  }
  .CommonContainerCard.empty__records.action {
    padding: 16px 0px 16px 0px !important;
  }
}

.tab-page {
  background: var(--neutral-12);
  border: var(--spacing-1) solid var(--neutral-9);
}
@media (max-width: 767px) {
  .tab-page {
    background: inherit;
    border: var(--spacing-0) solid var(--neutral-9);
  }
}
.tab-page .tab {
  border-bottom: var(--spacing-1) solid var(--neutral-9);
  margin-top: 16px;
}
@media (max-width: 767px) {
  .tab-page .tab {
    margin-top: 0px;
    background: var(--neutral-12);
    padding-top: 8px;
  }
}
.tab-page .tab-content {
  padding: var(--spacing-24) var(--spacing-16);
}
@media (max-width: 767px) {
  .tab-page .tab-content {
    padding: var(--spacing-16);
  }
}

.border-body-tbl {
  border: var(--spacing-1) solid var(--neutral-9);
}

.word-wrap {
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  max-height: 24px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 1;
  color: var(--font-color-disabled);
}

.BottomStickyBar {
  position: sticky;
  left: var(--spacing-0);
  right: var(--spacing-0);
  bottom: var(--spacing-0);
  z-index: 999;
  max-width: 1224px;
}
@media (max-width: 767px) {
  .BottomStickyBar {
    display: none;
  }
}

.footer-action {
  display: none;
}
@media (max-width: 767px) {
  .footer-action {
    display: block;
    padding: 16px 24px;
    background: #fff;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  }
  .footer-action ion-grid {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

.BottomMobileBar {
  display: none;
}
@media (max-width: 767px) {
  .BottomMobileBar {
    display: block;
    position: fixed;
    bottom: 24px;
    right: 24px;
  }
}

@media (max-width: 767px) {
  .add-footer .BottomMobileBar {
    bottom: 100px;
  }
}
@media (max-width: 767px) {
  .add-footer .BottomMobileBarText {
    bottom: 170px;
  }
}

.d-none {
  display: none;
}
@media (max-width: 991px) {
  .d-none {
    display: block;
  }
}

.m-none {
  display: block;
}
@media (max-width: 991px) {
  .m-none {
    display: none;
  }
}

.Skelton-pp {
  background: #e3e3e3;
  width: 100%;
  display: block;
  height: 10px;
}

.Skelton-fh {
  background: #C1C4C8;
  width: 100%;
  display: block;
  height: 10px;
}

.capitalize {
  text-transform: capitalize;
}

snl-page-title .content__body--title span {
  display: flex;
  color: #27364b;
  align-items: center;
  gap: 4px;
}
snl-page-title .content__body--title snl-icon {
  padding: 4px 8px;
}
snl-page-title .content__body--title h1 {
  margin: 0px;
}

.input__disabled .inp {
  background: var(--neutral-11) !important;
  color: var(--font-color-disabled) !important;
  pointer-events: none;
}
.input__disabled .inp label {
  background: var(--neutral-11) !important;
}
.input__disabled .inp .inp__container--input {
  color: #B8BFCA;
}

/* ------------------------> Filters Dropdown Style <------------------------ */
.CommonFiltersPanel {
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: initial;
  gap: var(--spacing-16);
}
.CommonFiltersPanel .FiltersHolder {
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
}
.CommonFiltersPanel .ResetFiltersTxt {
  height: 48px;
  min-width: 130px;
}
@media screen and (max-width: 1300px) {
  .CommonFiltersPanel .ResetFiltersTxt {
    width: 124px;
  }
}
.CommonFiltersPanel .ResetFiltersTxt .ResetAllTxt {
  color: var(--font-color-disabled);
  text-decoration-line: none;
  cursor: not-allowed;
}
.CommonFiltersPanel .ResetFiltersTxt .ResetAllTxt.active {
  color: var(--primary-default);
  cursor: pointer;
}
.CommonFiltersPanel .filter-list {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-16);
}

.TableWrapper {
  margin-top: var(--spacing-24);
  overflow-x: auto;
  overflow-y: auto;
  border: var(--spacing-1) solid var(--neutral-9);
  border-radius: var(--spacing-8);
}
@media (max-width: 991px) {
  .TableWrapper {
    margin-top: var(--spacing-8);
    border: var(--spacing-0) solid var(--neutral-9);
  }
}

ion-item {
  --min-height:0px;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  --padding-start: 0px;
}

.list-md {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

ion-col {
  padding: 0px;
}

.horizontal-line {
  border-bottom: 1px solid var(--neutral-9);
}

ion-radio::part(container) {
  width: 16px;
  height: 16px;
  border-radius: 999px;
  border: 2px solid #ddd;
}
ion-radio::part(mark) {
  background: none;
  transition: none;
  transform: none;
  border-radius: 0;
}
ion-radio.radio-checked::part(container) {
  background: #008ECF;
  border-color: transparent;
}
ion-radio.radio-checked::part(mark) {
  border-style: solid;
  border-color: #fff;
  transform: rotate(45deg);
  width: 8px;
  height: 8px;
  border-width: 4px;
  border-radius: 999px;
}

ion-checkbox::part(container) {
  border-radius: 4px;
  border-color: #94A3B8;
  --checkbox-background-checked:#008ECF;
  --size: 20px;
  padding: 2px;
}
ion-checkbox::part(mark) {
  background: none;
  transition: none;
  transform: none;
  border-radius: 0;
}
ion-checkbox.checkbox-checked::part(container) {
  background: #008ECF;
  border-color: transparent;
}
ion-checkbox.checkbox-checked::part(mark) {
  border-style: solid;
  border-color: #fff;
  width: 18px;
  height: 18px;
  --checkmark-width: 3px;
}

ion-select.ionic-dropdown {
  --border-color: none;
  --padding-start: 0px;
  border: var(--border-width-1) solid var(--neutral-9);
  border-radius: 4px;
}
ion-select.ionic-dropdown.input__disabled {
  background: var(--neutral-11) !important;
  color: var(--font-color-disabled) !important;
  pointer-events: none;
}
ion-select.ionic-dropdown.select-label-placement-floating {
  font-family: var(--font-family-roboto-regular);
  font-size: var(--font-size-16);
  line-height: var(--line-height-24);
  color: var(--font-color-secondary);
  padding-left: 14px;
  min-height: 48px;
}
ion-select.ionic-dropdown.select-label-placement-floating::part(label) {
  margin-top: -5px;
}
ion-select.ionic-dropdown.select-label-placement-floating.has-value {
  font-weight: 700 !important;
  color: var(--font-color-primary);
}
ion-select.ionic-dropdown.select-label-placement-floating.has-value::part(label) {
  color: var(--font-color-primary);
  margin-top: 0px;
  transform: translateY(-8px);
  background: var(--neutral-12);
  font-size: var(--spacing-12);
  line-height: var(--spacing-16);
  font-family: var(--font-family-roboto-regular);
  font-weight: 400;
  padding-left: 0px;
}
ion-select.ionic-dropdown.select-label-placement-floating.has-value::part(container) {
  text-transform: uppercase;
}
ion-select.ionic-dropdown.select-label-placement-floating.select-expanded {
  border: var(--border-width-1) solid var(--primary-default);
}
ion-select.ionic-dropdown.select-label-placement-floating.select-expanded::part(label) {
  margin-top: 0px;
  color: var(--primary-default);
  transform: translateY(-8px);
  background: var(--neutral-12);
  font-size: var(--spacing-12);
  line-height: var(--spacing-16);
  font-family: var(--font-family-roboto-regular);
  font-weight: 400;
  padding-left: 0px;
}
ion-select.ionic-dropdown.select-label-placement-floating.select-expanded::part(icon) {
  color: var(--primary-default);
}

.ios ion-select.ionic-dropdown.select-label-placement-floating.has-value {
  --padding-bottom: 11px;
}

.datepicker .inp snl-icon i {
  color: var(--neutral-6) !important;
}
@media (max-width: 991px) {
  .datepicker .inp {
    min-width: 100%;
  }
}

ion-select-popover ion-item.sc-ion-select-popover-md {
  --inner-border-width: 0;
  border-bottom: var(--border-width-1) solid var(--neutral-10);
}
ion-select-popover ion-item.sc-ion-select-popover-md ion-radio.sc-ion-select-popover-md::part(label) {
  padding: var(--spacing-12) var(--spacing-16);
  font-family: var(--font-family-roboto-regular);
  font-size: var(--font-size-16);
  line-height: var(--line-height-24);
  letter-spacing: var(--letter-spacing-2);
  font-style: var(--font-style-normal);
  color: var(--font-color-primary);
  border-bottom: var(--border-width-1) solid var(--neutral-10);
  --icon-text-color: var(--neutral-6);
}
ion-select-popover ion-item.sc-ion-select-popover-md.select-interface-option.upperinput.md.sc-ion-select-popover-md.item.item-lines-default.item-control-needs-pointer-cursor.ion-activatable.ion-focusable.hydrated:hover {
  --background: var(--neutral-11) !important;
  --icon-text-color: var(--primary-hover);
}
ion-select-popover .sc-ion-select-popover-ios.ios {
  font-family: var(--font-family-roboto-regular);
  font-size: var(--font-size-16);
  line-height: var(--line-height-24);
  letter-spacing: var(--letter-spacing-2);
  font-style: var(--font-style-normal);
  color: var(--font-color-primary);
  border-bottom: var(--border-width-1) solid var(--neutral-10);
  --icon-text-color: var(--neutral-6);
}
ion-select-popover .sc-ion-select-popover-ios.ios::part(label) {
  padding: var(--spacing-12) var(--spacing-16);
  margin: var(--spacing-0);
}

.sc-ion-select-popover-md-h, .sc-ion-select-popover-ios-h {
  max-height: 200px;
}

.action-sheet-group.sc-ion-action-sheet-ios:first-child {
  margin-top: 10px;
  max-height: 240px;
}

.action-sheet-container.sc-ion-action-sheet-md {
  max-height: 300px;
}

.action-sheet-button.sc-ion-action-sheet-ios, .action-sheet-button.sc-ion-action-sheet-md {
  min-height: inherit;
  font-family: var(--font-family-roboto-regular);
  font-size: var(--font-size-16);
  line-height: var(--line-height-24);
  letter-spacing: var(--letter-spacing-2);
  font-style: var(--font-style-normal);
  color: var(--font-color-primary);
  border-bottom: var(--border-width-1) solid var(--neutral-10);
  --icon-text-color: var(--neutral-6);
}

.sc-ion-action-sheet-md .action-sheet-group .action-sheet-button, .sc-ion-action-sheet-ios .action-sheet-group .action-sheet-button {
  color: var(--font-color-primary);
  font-family: var(--font-family-roboto-regular);
  font-size: var(--font-size-16);
  line-height: var(--line-height-24);
  letter-spacing: var(--letter-spacing-2);
  font-style: var(--font-style-normal);
  color: var(--font-color-primary);
  border-bottom: var(--border-width-1) solid var(--neutral-10);
  --icon-text-color: var(--neutral-6);
}
.sc-ion-action-sheet-md .action-sheet-group .action-sheet-button .action-sheet-button-inner.sc-ion-action-sheet-md, .sc-ion-action-sheet-ios .action-sheet-group .action-sheet-button .action-sheet-button-inner.sc-ion-action-sheet-md {
  justify-content: center;
}
.sc-ion-action-sheet-md .action-sheet-group.sc-ion-action-sheet-md:first-child, .sc-ion-action-sheet-ios .action-sheet-group.sc-ion-action-sheet-md:first-child {
  margin: 10px;
  border-radius: 13px;
}
.sc-ion-action-sheet-md .action-sheet-group-cancel.sc-ion-action-sheet-md, .sc-ion-action-sheet-ios .action-sheet-group-cancel.sc-ion-action-sheet-md {
  padding-bottom: 0px;
  border-radius: 13px;
  margin: 0px 10px 10px 10px;
  font-weight: 700;
  color: red !important;
}
.sc-ion-action-sheet-md .action-sheet-cancel.sc-ion-action-sheet-md, .sc-ion-action-sheet-md .action-sheet-cancel.sc-ion-action-sheet-ios, .sc-ion-action-sheet-ios .action-sheet-cancel.sc-ion-action-sheet-md, .sc-ion-action-sheet-ios .action-sheet-cancel.sc-ion-action-sheet-ios {
  font-weight: 700;
  color: var(--primary-default);
}

.inp__helperText:empty {
  padding-top: 0px !important;
}

snl-action-panel .buttons-gap {
  gap: 16px;
}

@media (max-width: 767px) {
  snl-button.full-width {
    width: 100%;
  }
  snl-button.full-width button {
    width: 100%;
  }
}

.ion-modal--full-height {
  --border-radius: 8px;
  --min-width: 800px;
  --height: auto;
  --max-width: 800px;
  --max-height:calc(100% - 80px);
}
.ion-modal--full-height ion-footer {
  box-shadow: none !important;
}
@media (max-width: 767px) {
  .ion-modal--full-height {
    --min-width: 100%;
    --height: 100%;
    --max-width: 100%;
    --border-radius: 0px;
    --max-height:100%;
  }
  .ion-modal--full-height ion-footer {
    box-shadow: 0px -2px 15px 0px rgba(15, 26, 42, 0.1019607843) !important;
  }
  .ion-modal--full-height ion-footer snl-button {
    width: 100%;
  }
  .ion-modal--full-height ion-footer snl-button button {
    width: 100%;
  }
}
.ion-modal--full-height .wrapper {
  padding: 0px 24px 24px 24px;
  max-height: calc(100vh - 310px);
  overflow: auto;
}
.ion-modal--full-height ion-content {
  --padding-start:16px;
  --padding-end:16px;
  --padding-top:16px;
}
.ion-modal--full-height ion-content ion-row {
  gap: 16px;
}
.ion-modal--full-height .content {
  gap: 16px;
}
.ion-modal--full-height .content ion-grid {
  padding-bottom: 16px;
  gap: 16px;
}
.ion-modal--full-height .content ion-grid:last-child {
  padding-bottom: 0px;
}

ion-modal.delete-modal {
  --border-radius: 8px;
  --width: fit-content;
  --height: fit-content;
}
ion-modal.delete-modal .header {
  padding: var(--font-size-24);
}
ion-modal.delete-modal .wrapper {
  padding: 0px 24px 24px 24px;
  max-width: 400px;
}
ion-modal.delete-modal .modal-footer {
  padding: var(--spacing-16);
}
@media (max-width: 767px) {
  ion-modal.delete-modal .modal-footer .modal-actions snl-button {
    width: 100%;
  }
  ion-modal.delete-modal .modal-footer .modal-actions snl-button button {
    width: 100%;
  }
}
@media (max-width: 767px) {
  ion-modal.delete-modal {
    --width: 90%;
  }
  ion-modal.delete-modal::part(backdrop) {
    --backdrop-opacity: 0.9 !important;
  }
}
ion-modal.filter-modal {
  --height:auto;
  --border-radius: 24px 24px 0px 0px;
}
ion-modal.filter-modal::part(backdrop) {
  --backdrop-opacity: 0.9 !important;
}
ion-modal.filter-modal.ios.modal-sheet {
  --border-radius: 24px;
}
ion-modal.filter-modal snl-input snl-icon {
  color: var(--neutral-6);
}
ion-modal.filter-modal .filter-content-list {
  display: flex;
  gap: 16px;
}
ion-modal.filter-modal ion-item {
  --border-color:var(--neutral-10);
}
ion-modal.filter-modal .header .heading {
  display: flex;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 8px;
  border-bottom: 2px solid #F1F4F9;
}
ion-modal.filter-modal .header .heading .icon {
  min-height: 48px;
  min-width: 48px;
  justify-content: center;
  align-items: center;
  display: flex;
}
ion-modal.filter-modal .dropdown-content .search-bar {
  padding: 8px 16px;
  border-bottom: 1px solid #F1F4F9;
}
ion-modal.filter-modal .dropdown-content .item-content {
  overflow-y: auto;
}
ion-modal.filter-modal .dropdown-content ion-list-header {
  padding: 2px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
ion-modal.filter-modal .dropdown-content ion-item {
  border-bottom: 1px solid #F1F4F9;
  padding: 16px;
  --padding-bottom: 12px;
  --padding-top: 12px;
}
ion-modal.filter-modal .dropdown-content .inner-content {
  overflow: auto;
  max-height: 284px;
}
ion-modal.filter-modal .dropdown-content .inner-content .list {
  min-height: 48px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  cursor: pointer;
  border-bottom: 1px solid #F1F4F9;
}
ion-modal.filter-modal .dropdown-content .inner-content .list:last-child {
  border-bottom: none;
}
ion-modal.filter-modal .dropdown-content .inner-content .list:hover {
  background-color: #F1F4F9;
}
ion-modal.filter-modal .dropdown-content .inner-content .list ion-label {
  padding: 0px 16px;
}
ion-modal.filter-modal .filter-list ion-label {
  padding: 16px;
}
ion-modal.filter-modal .button {
  padding: 16px;
  display: flex;
  gap: 16px;
  box-shadow: none;
}
ion-modal.filter-modal .button snl-button {
  width: 100%;
}
ion-modal.filter-modal .button snl-button button {
  width: 100%;
}
ion-modal.filter-modal .filter-box {
  background-color: #F1F4F9;
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 12px 16px;
}
ion-modal.filter-modal .filter-box ion-title {
  padding: 0px;
}
ion-modal.filter-modal .filter-box .reset-btn {
  color: #B8BFCA;
  font-size: 16px;
  text-align: end;
  text-decoration: none;
}
ion-modal.filter-modal .modal-footer-mob {
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  border-bottom: 1px solid #F1F4F9;
}
ion-modal.filter-modal .filter-item {
  height: 56px !important;
}
ion-modal.filter-modal .bold-label {
  font-family: var(--font-family-roboto-bold) !important;
}
ion-modal.filter-modal .active-reset {
  color: #008ECF !important;
}

ion-skeleton-text.input {
  width: 100%;
  border-radius: 4px;
  height: 48px;
}
ion-skeleton-text.wPx-100 {
  width: 100px;
}
ion-skeleton-text.dropdown {
  width: 130px;
  height: 48px;
  border-radius: 4px;
}
ion-skeleton-text.search {
  width: 260px;
  height: 48px;
  border-radius: 4px;
}
ion-skeleton-text.button {
  width: 200px;
  height: 48px;
  border-radius: 4px;
}
@media (max-width: 767px) {
  ion-skeleton-text.button {
    width: inherit;
  }
}
ion-skeleton-text.mobile-button {
  max-width: 48px;
  min-width: 48px;
  height: 48px;
  border-radius: 4px;
}
ion-skeleton-text.toogle {
  width: 60px;
  height: 30px;
  border-radius: 999px;
}
ion-skeleton-text.text {
  margin-top: 8px;
  width: 130px;
  height: 10px;
}
ion-skeleton-text.heading {
  width: 160px;
  height: 18px;
}
ion-skeleton-text.w-100 {
  width: 100%;
}
ion-skeleton-text.w-80 {
  width: 80%;
}
ion-skeleton-text.w-50 {
  width: 50%;
}
ion-skeleton-text.w-20 {
  width: 20%;
}
ion-skeleton-text.tabskeleton {
  margin-top: 8px;
  width: 120px;
  height: 32px;
  border-radius: 4px 4px 0px 0px;
}
ion-skeleton-text.drga-drop {
  border-radius: var(--border-radius-8);
  min-height: 114px;
  margin-bottom: 12px;
}
ion-skeleton-text.name {
  width: 130px;
  margin-bottom: 8px;
  height: 12px;
}
ion-skeleton-text.designation {
  width: 70px;
}

ion-thumbnail.profile {
  --border-radius: var(--border-radius-999);
  width: 32px;
  height: 32px;
}

ion-content {
  --background: none;
}

ion-infinite-scroll-content {
  min-height: 50px;
}
ion-infinite-scroll-content .infinite-loading {
  margin-bottom: 0px;
}

ion-footer.bottom-sticky {
  max-width: 1224px;
}
@media (max-width: 767px) {
  ion-footer.bottom-sticky {
    display: none;
  }
}

ion-grid {
  --ion-grid-padding: 0px;
}
ion-grid ion-row ion-col:nth-child(1) {
  padding-right: 18px !important;
}
@media (max-width: 991px) {
  ion-grid ion-row ion-col:nth-child(1) {
    padding-right: 0px !important;
  }
}
ion-grid ion-row ion-col:nth-child(2) {
  padding-right: 12px;
  padding-left: 6px;
}
@media (max-width: 991px) {
  ion-grid ion-row ion-col:nth-child(2) {
    padding-right: 0px;
    padding-left: 0px;
  }
}
ion-grid ion-row ion-col:nth-child(3) {
  padding-right: 6px;
  padding-left: 12px;
}
@media (max-width: 991px) {
  ion-grid ion-row ion-col:nth-child(3) {
    padding-right: 0px;
    padding-left: 0px;
  }
}
ion-grid ion-row ion-col:nth-child(4) {
  padding-left: 18px;
}
@media (max-width: 991px) {
  ion-grid ion-row ion-col:nth-child(4) {
    padding-left: 0px;
  }
}
ion-grid ion-row ion-col:last-child {
  padding-right: 0px;
}
@media (max-width: 991px) {
  ion-grid ion-row ion-col {
    padding-right: 0px;
    padding-left: 0px;
  }
}

body {
  margin-top: constant(safe-area-inset-top);
  margin-top: env(safe-area-inset-top);
  background-color: #ffffff;
}

.plt-desktop body {
  position: inherit !important;
  overflow: auto !important;
  transform: inherit !important;
}
.plt-desktop body.backdrop-no-scroll {
  overflow: hidden !important;
  position: fixed !important;
}
.plt-desktop body.backdrop-no-scroll .BottomStickyBar {
  position: absolute;
  left: 212px;
}

:root {
  --ion-background-color: #ffffff; /* Light theme background */
  --ion-text-color: #000000; /* Light theme text */
  --ion-safe-area-bottom: 14px;
}

[data-theme=dark] {
  --ion-background-color: #121212; /* Dark theme background */
  --ion-text-color: #ffffff; /* Dark theme text */
}

.ios-device .ios-margin-bottom {
  padding-bottom: 70px !important;
}

.ios {
  --ion-safe-area-top: -1 !important;
  --ion-safe-area-bottom: 14px;
}

.native .ios.footer-ios {
  margin-bottom: 60px;
}
.native .ios.footer-ios.footer-action {
  margin-bottom: 0px;
}
.native.ios .footer-ios-extra-space {
  margin-bottom: 60px;
}
.native.ios .footer-ios-extra-space.filter-content {
  margin-bottom: 80px;
}
.native.ios .custom-modal-class .ios.footer-ios {
  margin-bottom: 0px;
}
.native .action-sheet-wrapper.ion-overlay-wrapper.sc-ion-action-sheet-ios {
  margin-bottom: 60px;
}

.custom-modal {
  --backdrop-opacity: 1;
  background: #fff !important;
  overflow: hidden !important;
}
.custom-modal::part(content) {
  border-radius: 20px;
  background: #fff !important;
  box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.32);
}