
/* Hide the toolbar on larger screens (tablet and desktop) */
@import "src/assets/themes/mixin-breakpoints.scss";



@mixin letter-style {
  letter-spacing: var(--letter-spacing-2);
  font-style: var(--font-style-normal);
}

@mixin header-style {
  font-size: var(--font-size-26);
  line-height: var(--line-height-40);
}

@mixin subheading-style {
  font-size: var(--font-size-20);
  line-height: var(--line-height-32);
}

.header-h1 {
  font-family: var(--font-family-pt-serif-bold);
  @include header-style();
  @include letter-style();
}

.header-h2 {
  font-family: var(--font-family-roboto-bold);
  @include header-style();
  @include letter-style();
}

.sub-heading-s1 {
  font-family: var(--font-family-roboto-bold);
  @include subheading-style();
  @include letter-style();
}

.sub-heading-s2 {
  font-family: var(--font-family-roboto-regular);
  @include subheading-style();
  @include letter-style();
}

.section-title {
  font-family: var(--font-family-roboto-bold);
  font-size: var(--font-size-16);
  line-height: var(--line-height-24);
  @include letter-style();
}

.body-b1 {
  font-family: var(--font-family-roboto-regular);
  font-size: var(--font-size-16);
  line-height: var(--line-height-24);
  color: var(--font-color-secondary);
  @include letter-style();
}

.body-b2 {
  font-family: var(--font-family-roboto-bold);
  font-size: var(--font-size-14);
  line-height: var(--line-height-24);
  @include letter-style();
}

.body-b3 {
  font-family: var(--font-family-roboto-regular);
  font-size: var(--font-size-14);
  line-height: var(--line-height-24);
  @include letter-style();
}

.body-b {
  font-family: var(--font-family-roboto-regular);
  font-size: var(--font-size-14);
  line-height: var(--line-height-24);
  @include letter-style();
}

.labels {
  font-family: var(--font-family-roboto-bold);
  font-size: var(--font-size-12);
  line-height: var(--line-height-16);
  @include letter-style();
}

.caption {
  font-family: var(--font-family-roboto-regular);
  font-size: var(--font-size-12);
  line-height: var(--line-height-16);
  @include letter-style();
}

.mobile-only-toolbar {
  display: none;
  --background: var(--neutral-12)
}
.header-md {
  -webkit-box-shadow: inherit;
}

.mb-16{
  margin-bottom: 16px;
}

/* Show the toolbar only on small screens (mobile) */
@include media-breakpoint-only(sm) {
  .mobile-only-toolbar {
    display: flex;
    height: 64px;
    border-bottom: 1px solid #DBE3ED;
    align-items: center;
    justify-content: center;
    
  }
}
ion-header{
  .word-wrap{max-height: inherit;}
}

.navigation-header{
  ion-menu-button, ion-icon{color:var(--neutral-6)}
  .header{padding:24px;}
  .navigation-title{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  ion-title{
    padding-inline: 0px;
  }
  ion-buttons{
    cursor: pointer;
    color: #788496;
    &.buttons-first-slot{
      padding: 16px;
      width: 48px;
      height: 48px;
      margin-left: 4px;
    }
    &.buttons-last-slot{
      width:48px;
      height: 48px;
      margin-right: 4px;
      .fa-bell{font-size: 16px;}
    }
    
  }
  
}

.header-ios ion-toolbar:last-of-type {
  --border-width: 0px;
}

ion-footer{
  padding: 16px;
  background: #fff;
  box-shadow: 0px -2px 15px 0px #0F1A2A1A;
  ion-grid{
    display: flex;
    flex-direction: column;
    gap: 16px;
    .button{
      display: flex;
      gap: 24px;
      &.footer-submit-btn{
        flex-direction: row-reverse;
        .save__draft,.save__submit{width:100%}
      }
      &.delete-cancel{
        flex-direction: column;
        .save__draft{
          display: flex;
          flex: 50%;
          gap: 24px;
          snl-button{width: 100%;}
        }
      }
      .snl-button[size^="large"]:not([iconOnly^="true"]) {
        padding: inherit;
        width: 100%;
      }
    }
  }

}

.add-footer{
  ion-footer{
    padding:16px 24px;
  }
}
.upperinput {
  input{
    text-transform: uppercase;
  }  
}

.profile-details{
  gap:16px;
  .uplaod-image{
      .drag-drop{
          border-radius: var(--border-radius-8);
          padding: var(--spacing-16);
          align-items: center;
          justify-content: space-between;
          display: flex;
          border: 1px solid #CBD4E1;
          border-style: dashed;
          margin-bottom: var(--border-width-4);
          @include media-breakpoint-only(md) {
              padding: var(--spacing-0);
              border: none;
              margin: var(--spacing-0);
              .body-b1{display: none;}
              snl-button{
                  width: 100%;
                  button{
                      width: 100%;
                      margin-bottom: var(--spacing-16);
                  }
              }
          }
      }
  }
  .uploaded-files-name{
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 80%;
  }
  ul{padding-left: 10px;max-height: 32px;}
 
}

.form-layout{
  @include media-breakpoint-only(sm) {
    .gap-24 {gap: 16px;}
    .gap-200 {gap: 200px;}
  }
}
.uploaded-files-heading{
  font-family: var(--font-family-roboto-regular);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.2px;
}






